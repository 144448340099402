/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-x': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 5a3 3 0 11-6 0 3 3 0 016 0M8 7a2 2 0 100-4 2 2 0 000 4m.256 7a4.5 4.5 0 01-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/><path pid="1" d="M12.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7m-.646-4.854l.646.647.646-.647a.5.5 0 01.708.708l-.647.646.647.646a.5.5 0 01-.708.708l-.646-.647-.646.647a.5.5 0 01-.708-.708l.647-.646-.647-.646a.5.5 0 01.708-.708"/>',
    },
});
